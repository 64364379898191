<template>
  <ConfigProvider :locale="getAntdLocale">
    <AppProvider>
      <RouterView />
    </AppProvider>
  </ConfigProvider>
</template>

<script lang="ts" setup>
  import { ConfigProvider } from 'ant-design-vue'
  import { AppProvider } from '@admin/components/Application'
  import { useTitle } from '@admin/hooks/web/useTitle'
  import { useLocale } from 'framework/locales/useLocale'

  // import 'dayjs/locale/zh-cn'
  // support Multi-language
  const { getAntdLocale } = useLocale()

  // Listening to page changes and dynamically changing site titles
  useTitle()
</script>

<style>
  /* 滚动条 */
  body *::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  body *::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
  }

  body *::-webkit-scrollbar-thumb {
    background: rgb(224, 224, 224);
    border-radius: 2px;
  }

  body *::-webkit-scrollbar-thumb:hover {
    background: rgb(175, 175, 175);
  }

  body *::-webkit-scrollbar-corner {
    background: rgb(255, 255, 255);
  }
</style>
