import Aegis from 'aegis-web-sdk'
import { useGlobSetting } from '@admin/hooks/setting'
import { useUserStore } from '@admin/store/modules/user'
import { validateJsReported, validatePromise } from './valid'
export default function initAegis() {
  const { txCloudId } = useGlobSetting()
  const userStore = useUserStore()

  if (txCloudId) {
    const aegis = new Aegis({
      id: txCloudId, // 应用ID，即上报ID
      reportApiSpeed: true, // 接口测速
      reportAssetSpeed: true, // 静态资源测速
      hostUrl: 'https://aegis.qq.com', // 上报域名，中国大陆 rumt-zh.com
      spa: true, // spa 应用页面跳转的时候开启 pv 计算
      reportApiStatus: true, // 启用 API 请求状态码监控

      api: {
        apiDetail: true, // 上报接口请求参数和返回值
        reportRequest: true, // 全量接口上报
        usePerformanceTiming: true,
        retCodeHandler(data) {
          //返回码上报钩子函数
          try {
            data = JSON.parse(data)
          } catch (e) {
            console.log(e)
          }
          return {
            isErr: data.code !== 200,
            code: data.code,
          }
        },
      },
      beforeReport(log) {
        if (log.level === '4' && validateJsReported(log.msg)) {
          console.log('不上报这个js报错', log)
          return false
        }

        if (log.level === '8' && validatePromise(log.msg)) {
          console.log('不上报这个promise报错', log)
          return false
        }
        // console.log('上报', log)

        return log
      },
    })

    if (userStore?.getUserId) {
      aegis.setConfig({
        uin: userStore.getUserId, //上报userId
      })
    }

    window.aegis = aegis
    return aegis
  }
}
