//校验是否上报js报错
export const validateJsReported = (msg) => {
  if (!msg) return false

  const regexFilters = [
    //503、400、接口超时等报错会进入到js，过滤掉  ok
    /AxiosError/,

    //如果是我们项目的接口报错抛出的throw new Error则不上报 ok
    /transformResponseHook/,

    /Error: \[object Object\]/,

    //打包后编译出来的apply，无法确认问题，点击多次没发现有影响流程的点
    /func_/,

    //第三方打印面单时pdfjs报错时会同时出触发这个报错
    /uncaught exception: out of memory/,

    //未知文件跨域错误
    /Script error/,

    //图片加载出错
    /图片加载出错/,
  ]

  return regexFilters.some((regex) => regex.test(msg))
}

//校验是否上报promise报错
export const validatePromise = (msg) => {
  if (!msg) return false

  const regexFilters = [
    //表单校验不上报 ok
    /errorFields/,

    //富文本内部的报错过滤，粘贴图片组件会报错@wangeditor_editor-for-vue.js?v=56448f91:12475 Uncaught (in promise) Error: Cannot get upload server address 没有配置上传地址 ok
    /wangeditor_editor/,

    //503接口报错会进入到promise，过滤掉 ok
    /AxiosError/,

    //匹配所有从transformResponseHook上报但是不包含SQLSTATE 的字符串
    /^(?=.*transformResponseHook)(?!.*SQLSTATE).*/,
  ]

  return regexFilters.some((regex) => regex.test(msg))
}
