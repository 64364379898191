import type { Router } from 'vue-router'
import { useSystemInfo } from '@admin/layouts/default/systemMenu/useSystemInfo'
import { useAddTab } from '@admin/layouts/default/tabs/useMultipleTabs'

export function createTabMenuGuard(router: Router) {
  const { judgeRouteInfo } = useSystemInfo()

  router.beforeEach(async (to, from, next) => {
    useAddTab(to, from)
    judgeRouteInfo(to)
    next()
  })
}
