import type { Router } from 'vue-router'

import { useSystemMenuStore } from '@admin/store/modules/systemMenu'
import { useHandleIframe } from '@admin/views/common/iframe/hooks/useHandleIframe'
import { PageEnum } from '@admin/enums/pageEnum'

export function createBiJumpGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const systemMenuStore = useSystemMenuStore()
    const { conversionUrl } = useHandleIframe()
    if (to.path.indexOf('bi/page') > -1 || to.path.indexOf('bi-code/') > -1) {
      const findMenu = systemMenuStore.allMenuList.find((v) => v.path === to.path)
      if (findMenu) {
        const iframeUrl = conversionUrl(findMenu)
        next(iframeUrl.path)
      } else {
        next(PageEnum.ERROR_PAGE)
      }
    }
    next()
  })
}
