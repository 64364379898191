import type { Router } from 'vue-router'

import { usePermissionStoreWithOut } from '@admin/store/modules/permission'

import { useUserStoreWithOut } from '@admin/store/modules/user'

export function createPermissionGuard(router: Router) {
  const userStore = useUserStoreWithOut()
  const permissionStore = usePermissionStoreWithOut()

  router.beforeEach(async (to, from, next) => {
    if (userStore.token) {
      if (to.path === '/login') {
        next('/')
      } else {
        if (!permissionStore.menuList.length) await permissionStore.getMenuList()
        next()
      }
    } else {
      if (to.path === '/login') {
        next()
      } else {
        next(`/login?redirect=${to.fullPath}`)
      }
    }
  })
}
