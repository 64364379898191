import type { Router } from 'vue-router'
import { useAppStore } from '@admin/store/modules/app'
import { useMultipleTabStore } from '@admin/store/modules/multipleTab'
import { useUserStore } from '@admin/store/modules/user'
import { usePermissionStore } from '@admin/store/modules/permission'
import { removeTabChangeListener } from 'framework/logics/mitt/routeChange'
import { useSystemMenuStore } from '@admin/store/modules/systemMenu'

export function createStateGuard(router: Router) {
  router.afterEach((to) => {
    if (to.path === '/login') {
      const tabStore = useMultipleTabStore()
      const userStore = useUserStore()
      const appStore = useAppStore()
      const permissionStore = usePermissionStore()
      const systemMenuStore = useSystemMenuStore()
      appStore.resetAllState()
      permissionStore.resetState()
      tabStore.resetState()
      userStore.resetState()
      removeTabChangeListener()
      systemMenuStore.resetState()
    }
  })
}
