import type { Router } from 'vue-router'
// import { axiosCancelers } from '@admin/utils/http/axios/axiosCancel'
import { Modal, notification } from 'ant-design-vue'
import { warn } from 'framework/utils/log'
import { createAuthGuard } from './authGuard'
import { createPermissionGuard } from './permissionGuard'
// import { ceateRecordPageLoadTime } from './recordPageLoadTime'
import { createBiJumpGuard } from './biJumpGuard'
import { createStateGuard } from './stateGuard'
import nProgress from 'nprogress'
import { createTabMenuGuard } from './tabMenuGuard'

// Don't change the order of creation
export function setupRouterGuard(router: Router) {
  // createHttpGuard(router) //切换路由的时候终止请求，感觉可以清空，有需要就放开
  createAuthGuard(router) // 授权守卫一定要在权限前面，用来第三方授权后重定向回来URL
  createPermissionGuard(router)
  createBiJumpGuard(router) // 只处理bi-iframe跳转参数
  createTabMenuGuard(router)
  createScrollGuard(router)
  createMessageGuard(router)
  createProgressGuard(router)
  createStateGuard(router)
  // ceateRecordPageLoadTime(router)
}

/**
 * The interface used to close the current page to complete the request when the route is switched
 * @param router
 */
// function createHttpGuard(router: Router) {
//   router.beforeEach(async () => {
//     // Switching the route will delete the previous request
//     axiosCancelers?.removeAllPending()
//     return true
//   })
// }

// Routing switch back to the top
function createScrollGuard(router: Router) {
  const isHash = (href: string) => {
    return /^#/.test(href)
  }

  const body = document.body

  router.afterEach(async (to) => {
    // scroll top
    isHash((to as any & { href: string })?.href) && body.scrollTo(0, 0)
    return true
  })
}

/**
 * Used to close the message instance when the route is switched
 * @param router
 */
export function createMessageGuard(router: Router) {
  router.beforeEach(async () => {
    try {
      Modal.destroyAll()
      notification.destroy()
    } catch (error) {
      warn('message guard error:' + error)
    }
    return true
  })
}

export function createProgressGuard(router: Router) {
  //const { getOpenNProgress } = useTransitionSetting()
  router.beforeEach(() => {
    nProgress.start()
    return true
  })

  router.afterEach(() => {
    nProgress.done()
    return true
  })
}
